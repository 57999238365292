#creative-lab {

    .creative-lab {

        &-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-title {
            font-size: 7.5vw;
            cursor: default;
            white-space: nowrap;
            margin-top: 5rem;

            @include media-desktop {
                margin-top: 0;
            }

            &:hover {
                @include media-desktop {
                    .creative-lab-title-hide {
                        opacity: 1;
                        transform: translateY(0);
                    }

                    .creative-lab-desc {
                        opacity: 1;
                    }
                }
            }

            &-hide {
                display: inline-block;

                @include media-desktop {
                    transition: all 0.3s ease;
                    opacity: 0;
                    transform: translateY(105%);
                }
            }
        }

        &-desc {
            height: 100%;
            display: flex;
            align-items: center;

            @include media-desktop {
                transform: translateY(40px);
                opacity: 0;
            }
        }
    }
}

#creative-process {

    .creative-process {

        &-list {

            &-item {
                display: flex;
                flex-direction: row;
                margin: 1rem 0;
                @include stroke-hover();
                cursor: default !important;

                &-number {
                    margin-right: 1rem;
                }

                &-title {
                    width: 100%;
                    border-bottom: 1px solid $color-text;
                }
            }
        }
    }
}

#work {

    .work {

        &-wrap {

            &-item {
                margin-bottom: 7rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-subtitle {
            margin-top: 0.5rem;
            margin-bottom: 1rem;

            @include media-desktop {
                -webkit-text-stroke-color: $color-text;
                -webkit-text-stroke-width: 1.5px;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;

                &::selection {
                    -webkit-text-fill-color: $color-background;
                }
            }
        }

        &-text {

            &-wrap {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
            }
        }

        &-cta {
            margin-top: 1.5rem;

            @include media-desktop {
                margin-top: 0;
            }
        }

        &-img {
            object-fit: cover;
            aspect-ratio: 16/10;
        }

        &-item {

            &-desc {
                height: 100%;
                display: flex;
                align-items: center;
            }

            &-space {
                margin: 5rem 0;
            }
        }
    }
}