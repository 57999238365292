#intro {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;

    .intro {

        &-wrap {
            height: 100vh;
            position: relative;
        }

        &-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 75vh;
            width: 100%;
            position: relative;
            z-index: 3;

            @include media-desktop {
                height: 100%;

            }
        }

        &-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;

            &-main,
            &-second {
                position: absolute;
            }

            &-main {
                bottom: 5%;
                right: 0;
                transform: translateY(0);
                width: 80vh;
                z-index: 2;

                @include media-desktop {
                    bottom: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &-second {
                width: 6vh;
                right: 5vh;
                bottom: 8%;
                z-index: 1;

                @include media-desktop {
                    width: 10vh;
                    bottom: 20vh;
                }
            }


        }
    }
}

#badge {
    background: $color-white;
    padding: 2rem 0;
    position: relative;

    .container {
        position: relative;
    }
}

#book {

    .book {

        &-box {
            background: $color-white;
            color: $color-background;
            margin-top: 5rem;
            display: flex;
            flex-direction: column;
            padding: 1.5rem;


            @include media-desktop {
                flex-direction: row;
                padding: 1rem;
            }


            &-img {
                margin-top: -50px;
                margin-bottom: 2rem;
                object-fit: cover;

                @include media-desktop {
                    margin-bottom: 0;
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-left: 0;

                @include media-desktop {
                    padding-left: 3rem;
                }

                .btn {
                    border: 1px solid $color-background;
                    color: $color-background;
                    margin-top: 2rem;

                    @include media-desktop {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

#food {

    h3 {
        white-space: nowrap;
    }

    .food {

        &-text {
            max-width: 500px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &-item {
            margin: 30vh 0;
        }

        &-desc {
            margin-top: 2rem;

            @include media-desktop() {
                margin-top: 0;
                max-width: 600px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            h4 {
                color: $color-primary;
            }
        }

        &-desc.left {
            @include media-desktop() {
                margin-left: 8rem;
            }
        }

        &-desc.right {
            @include media-desktop() {
                margin-right: 8rem;
            }
        }

        &-print {
            background: $color-white;
            padding: 1rem;

            .btn {
                border: 1px solid $color-background;
                color: $color-background;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 1rem;
            }
        }
    }
}

#season {

    .swiper-slide {

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .slider-arrow {
        top: inherit;
        bottom: -60px;

        @include media-desktop() {
            bottom: inherit;
            top: -70px;
        }
    }

    .parallax {
        margin: 0;
        height: 50vh;

        &-image {
            height: 50vh;
        }

        @include media-desktop() {
            height: 80vh;

            .parallax-image {
                height: 100vh;
            }
        }
    }
}

#contact {
    margin: 0;

    .contact {

        &-form {

            &-warning {
                background: $color-text;
                color: $color-background;
                padding: 0.5rem 1rem;
                width: 100%;
                margin: 1rem 0;
                display: block;
            }

            &-warning.error {
                background: #e82626;
            }

            &-input,
            &-textarea,
            &-select {
                border: none;
                background-image: none;
                background-color: transparent;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                outline: none;

                border-bottom: 1px solid $color-text;
                background: transparent;
                color: $color-text;
                padding: 0.6rem 0;
                width: 100%;
                display: block;

                &::placeholder {
                    @include font-small();
                    color: $color-gray;
                }
            }

            &-select {
                @include font-small();
                color: $color-gray;
                padding: 1rem 0;

                option {
                    text-transform: none !important;
                }

                option:disabled {
                    @include font-small();
                }

            }

            &-submit {
                display: block;
                background: transparent;
                margin: 2em 0 2em auto;
                cursor: pointer;
            }
        }
    }
}