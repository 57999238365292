#detail-ourteam {

    .detail-ourteam {

        &-breadcrumb {
            margin-bottom: 2rem;
            display: block;
        }

        &-img {
            border-radius: 100%;
            width: 400px;
            height: 400px;
            object-fit: cover;
        }

        &-head {
            margin-top: 2rem;
            margin-bottom: 2rem;

            @include media-desktop {
                margin-top: 0;
            }
        }
    }
}

#detail-career {

    .detail-career {

        &-prehead {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;
        }

        &-head {
            margin-top: 2rem;
            margin-bottom: 2rem;

            @include media-desktop {
                margin-top: 0;
            }
        }

        &-date {
            margin-bottom: 1rem;
            color: $color-gray;
        }
    }
}