*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

:root {
    font-size: $font-size-root;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

body {
    @include font-body();
    font-family: "neue-haas-grotesk-text", sans-serif;
    background-color: $color-background;
    color: $color-text;
    overflow-x: hidden;
}

section {
    margin: $margin-section 0;
}

a {
    color: $color-text;
    text-decoration: none;
    cursor: pointer;
    position: relative;
}

a.link::before,
a.link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: $color-text;
    top: 100%;
    left: 0;
    pointer-events: none;
}

a.link::before {
    content: '';
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

a.link:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}

h1,
h2 {
    @include font-heading();
}

h3 {
    @include font-subheading();
}

h4,
h5,
h6 {
    @include font-subheading2();
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
small {
    @include reset-space();
}

small {
    @include font-small();
}

img {
    max-width: 100%;
}

::selection {
    color: $color-background;
    background: $color-text;
}

html[data-direction="down"] #header {
    transform: translate3d(0, -100%, 0);
}


html[data-direction="up"] #header {
    background: $color-background;
}