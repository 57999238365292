#cursor {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: transform;
}

@media (hover: hover) and (pointer: fine) {
    .cursor__circle {
        display: block;
        width: 300px;
        height: 400px;
        margin-top: -65%;
        margin-left: -50%;
        position: relative;
        overflow: hidden;

        .cursor__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
            z-index: 1;
            opacity: 0;
            transform-origin: top;
        }
    }
}

#header {
    padding: 0.5rem 0;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    transition: all 0.3s ease;

    #navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navbar {

            &-logo {
                h1 {
                    @include font-body();
                }

                &-img {
                    max-width: 60px;

                    @include media-desktop {
                        max-width: 100%;
                    }
                }
            }

            &-list {
                list-style: none;

                &-item {
                    display: inline-block;
                    margin: 0 2rem;

                    .link {
                        @include font-body();
                        color: $color-text;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &-menu {
                cursor: pointer;

                &-line {
                    height: 2px;
                    width: 30px;
                    background: $color-text;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

#navbar-mobile {
    background: $color-background;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: none;
    transform: scaleX(0);
    transform-origin: left;

    .navbar-mobile {

        &-list {
            margin-top: 10rem;
            list-style: none;

            &-item {
                display: block;
                margin: 2rem 0;

                .link {
                    @include font-body();
                    color: $color-text;
                }
            }
        }
    }
}

#loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $color-background;
    color: $color-text;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-origin: right;
    @include font-small();

    .loading-logo {

        &-img {
            max-width: 200px;
        }
    }

    .loading-text {
        position: fixed;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
}

#iframe,
#foodmenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
    opacity: 0;
    z-index: 9999;
    color: $color-background;
    background: rgba($color: #000000, $alpha: 0.8);

    .iframe,
    .foodmenu {

        &-wrap {
            overflow: hidden;
            position: absolute;
            top: 10%;
            left: 5%;
            width: 90%;
            height: 80%;
            background: #FFFFFF;
            border-radius: 15px;
            padding: 1rem;

            @include media-desktop() {
                top: 5%;
                height: 90%;
            }
        }

        &-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            z-index: 2;
            transition: all 0.3s ease;

            &:hover {
                transform: scale(0.9);
            }
        }

        &-frame {
            width: 100%;
            height: 100%;
            opacity: 0;

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        &-content {
            width: 100%;
            height: 100%;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}

#foodmenu {

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        position: relative;
        display: none;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .slider-arrow {
        z-index: 999;
        top: 25px;
        width: 25px;
        right: 15px;
    
        &-prev {
            right: 60px;
        }
    }
}

#footer {
    overflow: hidden;
    margin: 0;
    width: 100%;
    height: 100%;
    margin-top: $margin-section;

    .footer {

        &-title {
            text-align: center;
            margin-bottom: 2rem;
        }

        &-nav {
            background: #0f0f0f;
            color: $color-text;
            padding-top: 3rem;
            padding-bottom: 1.5rem;
            height: 100%;

            &-address {
                line-height: 1.8;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    @include font-small();
                    margin: 0.8rem 0;


                    &:first-child {
                        margin-top: 0;
                    }

                    a {
                        color: $color-text;
                    }

                    a.link::before,
                    a.link::after {
                        background: $color-text;
                    }
                }
            }
        }

        &-copyright {
            margin-top: 4rem;

            small {
                color: $color-text;
            }
        }
    }

    .btn {
        display: block;
        margin: auto;
    }
}